<template>
  <v-container fluid>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <v-data-table v-model="selectedItems" v-bind="$attrs"
                      :disable-sort="loadingDataTable"
                      :headers="headers"
                      :items="items"
                      :items-per-page="options.itemsPerPage"
                      :loading="loadingDataTable"
                      :options.sync="options"
                      :page.sync="options.page"
                      :server-items-length="totalPages"
                      class="elevation-1"
                      hide-default-footer
                      item-key="id">
          <v-progress-linear v-if="loadingDataTable" slot="progress" absolute indeterminate/>
          <template v-slot:top>
            <kurcc-data-table-toolbar :title="dataTableTitle">
              <template v-slot:search>
                <kurcc-data-table-search-box :loading="isSearching" :search-query.sync="searchQuery"/>
              </template>
              <kurcc-data-table-dialog-action-button v-if="showToolbarButton"
                                                     :buttonText="toolbarButtonText"
                                                     :dialogTitle="toolbarButtonDialogTitle"
                                                     :disabled="invalid"
                                                     :loading="doingCRUDOperations"
                                                     mode="add"
                                                     @cancel="resetSelectedItem"
                                                     @confirm="save($event)"
                                                     @dialog-open="resetSelectedItem">
                <slot :item="selectedItem" name="dialog"></slot>
              </kurcc-data-table-dialog-action-button>
            </kurcc-data-table-toolbar>
          </template>

          <template v-slot:item.actions="{ item }">

            <kurcc-data-table-dialog-action-button v-if="showUpdateAction"
                                                   :dialogTitle="updateDialogTitle"
                                                   :disabled="invalid"
                                                   :loading="doingCRUDOperations"
                                                   mode="edit"
                                                   @cancel="resetSelectedItem"
                                                   @confirm="save($event)"
                                                   @dialog-open="setSelectedItem(item)">
              <slot :item="selectedItem" name="dialog"/>
            </kurcc-data-table-dialog-action-button>

            <kurcc-data-table-dialog-action-button v-if="showDeleteAction"
                                                   :dialogTitle="deleteDialogTitle"
                                                   :loading="doingCRUDOperations"
                                                   mode="delete"
                                                   @cancel="resetSelectedItem"
                                                   @confirm="deleteItem($event)"
                                                   @dialog-open="setSelectedItem(item)">
            </kurcc-data-table-dialog-action-button>

          </template>
        </v-data-table>
      </form>
    </validation-observer>
    <v-row align="center" class="mt-3">
      <v-col class="text-start">
        <v-card flat>
          <v-card-text>
            Page {{ options.page }} of {{ totalPages }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="10" lg="9" md="7" sm="6" xl="10">
        <v-pagination v-model="options.page" :length="totalPages" total-visible="7"/>
      </v-col>
      <v-col>
        <v-select v-model="options.itemsPerPage" :items="[1, 2, 10,25,50,100]"
                  :label="$vuetify.lang.t('$vuetify.general.perPage')" dense hide-details
                  outlined persistent-hint/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'KurccDataTablePageTemplate',
  inheritAttrs: false,
  components: {
    KurccDataTableDialogActionButton: () => import('@/modules/app/components/KurccDataTableDialogActionButton'),
    KurccDataTableToolbar: () => import('@/modules/app/components/KurccDataTableToolbar'),
    KurccDataTableSearchBox: () => import('@/modules/app/components/KurccDataTableSearchBox'),
    ValidationObserver
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    dataTableTitle: {
      type: String,
      required: true,
      default: 'List'
    },
    defaultItem: {
      type: Object,
      required: true
    },
    showToolbarButton: {
      type: Boolean,
      default: true
    },
    showDeleteAction: {
      type: Boolean,
      default: true
    },
    showUpdateAction: {
      type: Boolean,
      default: true
    },
    toolbarButtonDialogTitle: {
      type: String
    },
    toolbarButtonText: {
      type: String
    },
    deleteDialogTitle: {
      type: String
    },
    updateDialogTitle: {
      type: String
    },
    actionsSuffix: {
      type: String,
      required: true
    },
    getAllActionAdditionalParams: {
      type: Array,
      required: false,
      default: undefined
    },
    initialSortByColumn: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      selectedItemIndex: -1,
      selectedItem: null,
      loadingDataTable: true,
      items: [],
      selectedItems: [],
      totalPages: 0,
      actions: {
        create: 'create',
        update: 'update',
        delete: 'delete',
        getAll: 'getAll'
      },
      doingCRUDOperations: false,
      updatingStatus: -1,
      updatingAdminStatus: -1,
      closeDialogMethod: undefined,
      options: {
        sortBy: [],
        sortDesc: [false],
        mustSort: true,
        page: 1,
        itemsPerPage: 10
      },
      searchQuery: '',
      isSearching: false,
      debounceTimeout: null
    }
  },
  computed: {
    isEditing () {
      return this.selectedItemIndex > -1
    },
    firstHeaderColumnValue () {
      return this.headers[0].value
    },
    getDataTableTitle () {
      return typeof this.isSearching === 'string' ? this.isSearching : ''
    }
  },
  watch: {
    showToolbarButton: {
      handler (value) {
        this.validateProp(value, 'toolbarButtonDialogTitle')
        this.validateProp(value, 'toolbarButtonText')
      },
      immediate: true
    },
    showDeleteAction: {
      handler (value) {
        this.validateProp(value, 'deleteDialogTitle')
      },
      immediate: true
    },
    showEditAction: {
      handler (value) {
        this.validateProp(value, 'updateDialogTitle')
      },
      immediate: true
    },
    options: {
      handler: 'getAllData',
      deep: true
    },
    searchQuery: function () {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.isSearching = true
        this.getAllData()
      }, 1000)
    }
  },
  methods: {
    validateProp (value, propName) {
      if (value && typeof this[propName] === 'undefined') {
        console.error(`"property ${propName} is required."`)
      }
    },
    setSelectedItem (item) {
      this.selectedItemIndex = this.items.indexOf(item)
      this.selectedItem = Object.assign({}, item)
    },
    save (closeMethod) {
      this.closeDialogMethod = closeMethod
      this.doingCRUDOperations = true

      if (this.isEditing) {
        this.updateItem()
      } else {
        this.createItem()
      }
    },
    createItem () {
      this.$store.dispatch(this.prepareActionName(this.actions.create), { ...this.selectedItem })
        .then(() => this.cleanupAfterSuccessfulCRUDOperation())
        .finally(() => {
          this.doingCRUDOperations = false
        })
    },
    updateItem (item) {
      let payload = null

      if (item) {
        this.updatingStatus = item.id
        payload = {
          id: item.id,
          enabled: !item.enabled
        }
      } else {
        payload = { ...this.selectedItem }
      }

      this.$store.dispatch(this.prepareActionName(this.actions.update), payload)
        .then(() => this.cleanupAfterSuccessfulCRUDOperation())
        .finally(() => {
          this.doingCRUDOperations = false
          this.updatingStatus = -1
        })
    },
    deleteItem (closeMethod) {
      this.doingCRUDOperations = true
      this.closeDialogMethod = closeMethod
      this.deleteSelectedItem()
    },
    deleteSelectedItem () {
      this.$store.dispatch(this.prepareActionName(this.actions.delete), { ...this.selectedItem })
        .then(() => this.cleanupAfterSuccessfulCRUDOperation())
        .finally(() => {
          this.doingCRUDOperations = false
        })
    },
    resetSelectedItem () {
      this.selectedItem = Object.assign({}, this.defaultItem)
      this.selectedItemIndex = -1
    },
    cleanupAfterSuccessfulCRUDOperation () {
      if (this.closeDialogMethod) {
        this.closeDialogMethod()
      }
      this.getAllData()
      this.resetSelectedItem()
    },
    prepareActionName (action) {
      if (action === this.actions.getAll) {
        return action + this.$pluralize(this.$lodash.startCase(this.actionsSuffix), 2)
      } else {
        return action + this.$lodash.startCase(this.actionsSuffix)
      }
    },
    getAllData () {
      const {
        sortBy: orderBy,
        sortDesc,
        itemsPerPage: perPage,
        page: currentPage
      } = this.options
      this.loadingDataTable = true

      this.$store.dispatch(this.prepareActionName(this.actions.getAll), {
        perPage,
        currentPage,
        withIncludes: true,
        orderBy,
        sortedBy: sortDesc[0] ? 'desc' : 'asc',
        searchQuery: this.searchQuery,
        additionalParams: this.getAllActionAdditionalParams
      }).then((res) => {
        this.items = res.items
        if (this.currentPage > res.pagination.total_pages) {
          this.currentPage = 1
        }
        this.totalPages = res.pagination.total_pages
        this.loadingDataTable = false
      }).finally(() => {
        this.isSearching = false
      })
    },
    submit () {
      this.$refs.observer.validate()
    }
  },
  created () {
    this.options.sortBy = [this.initialSortByColumn ? this.initialSortByColumn : this.firstHeaderColumnValue]
    this.getAllData()
  },
  mounted () {
    this.resetSelectedItem()
  }
}
</script>
